<template>
  <div class="cashout">
    <p class="path">Frequently Asked Questions > TayoCash Services > Cash Out</p>
    <h3>Cash Out</h3>
    <!-- <h4 id="c_o1">How do I Cash Out money from my TayoCash wallet?</h4>
    <div class="paragraph">
      <p>
        You can Cash Out or withdraw money from your TayoCash Wallet through any of our TayoCash Partners or Merchants, subject to the applicable <a class="link" href="/faq/feesandcharges">service fee</a>.
      </p>
    </div>

    <h4 id="c_o2">How do I Cash Out through a TayoCash Partner or Merchant?</h4>
    <div class="paragraph">
      <ol>
        <li>Open your TayoCash app. Press <span class="vtxt-align">Cash Out</span> and enter the amount you want to Cash Out.</li>

        <li>Tap <span class="vtxt-align">Select a Partner, Bank or Merchant</span> to see the list of Partners or Merchants. You may also tap <span class="vtxt-align">Send now via Partner</span> to see all nearby partners.</li>

        <li>Select a Partner/Merchant and wait for the Partner/Merchant to acknowledge your Cash Out request.</li>

        <li>Go to the Partner/Merchant to get your Cash Out amount, minus the applicable service fee. Your TayoCash Wallet should reflect the deducted Cash Out amount.</li>
      </ol>
    </div> -->

    <h4 id="c_o3">How to Cash Out?</h4>
    <div class="paragraph">
      <ol>
        <li>Log in to your TayoCash Account and select <span class="vtxt-align">Cash Out</span>.</li>
        <li>Select your preferred Cash Out Partner and check the instructions on how to Cash Out.</li>
        <li>Enter or select the amount that you would like to Cash Out.</li>
        <li>Review the completeness of the transaction details and enter the 6-digit OTP verification code.</li>
        <li>Save the Reference Code by tapping the “Save to Gallery” icon.</li>
        <li>Go to the nearest Partner outlet and fill out the “Payment Form.”</li>
        <li>Submit your accomplished  “Payment Form” to the Teller along with a government-issued valid ID.</li>
        <li>You will receive an SMS notification that the Cash Out transaction has been completed.</li>
        <li>Remember to always count your money before leaving the outlet.</li>
      </ol>
    </div>

    <h4 id="c_o4">Where can I Cash Out?</h4>
    <div class="paragraph">
      <p>Cash Out via TayoCash Partner outlets (Over-the-Counter). <br>You can do Over-The-Counter Cash Out transactions from any of our <a class="link" href="https://assets.prod.tayocash.com/docs/cashout_partners.pdf" target="_blank">partner outlets</a>.</p>
    </div>

    <h4 id="c_o5">How much is the Cash Out Fee?</h4>
    <div class="paragraph">
      <p>The Cash Out fee is 1.25% of the total cash out amount. The fee will be charged with all Cash Out transactions regardless of amount/value.</p>
    </div>

    <h4 id="c_o6">How much can I Cash Out?</h4>
    <div class="paragraph">
      <p>
        The amount you can Cash Out or withdraw depends on your TayoCash Membership wallet limit.
        <br>
        Click <router-link @click.native="removehash" class="h-violet" :to="{ path: '/faq/membership', hash: '#mbs3' }" replace>here</router-link> to learn about the corresponding wallet limit for each TayoCash Membership type.
      </p>
    </div>

    <h4 id="c_o7">I can’t Cash Out, what do I do?</h4>
    <div class="paragraph">
      <p>The Cash Out feature is only available for fully verified users. To learn how to get verified, click <a class="link" href="/faq/verification">here</a></p>
    </div>

    <h4 id="c_o8">Can I ask someone to Cash Out on my behalf?</h4>
    <div class="paragraph">
      <p>The person who owns the TayoCash account is the only one authorized to Cash Out. TayoCash and our partners do not accept third-party requests for Cash out transactions.</p>
    </div>

    <h4 id="c_o9">I received an SMS confirmation for an unauthorized Cash Out transaction, what should I do?</h4>
    <div class="paragraph">
      <p>We're sorry to hear about this. To avoid further unauthorized use of your account, please change your MPIN immediately.</p>
      <p>We will try our best to assist you in retrieving your funds, but please take note that we cannot guarantee anything.</p>
      <p>Upon your request, we can temporarily suspend your account to prevent any further unauthorized transactions while we investigate the situation. To do so, please submit a ticket and include the following details:
        <ul class="mt-0">
          <li class="ma-0">Details of your concern</li>
          <li class="ma-0">Your TayoCash Registered Name</li>
          <li class="ma-0">Your TayoCash Registered Mobile Number</li>
          <li class="ma-0">Date and time of transaction</li>
          <li class="ma-0">Transaction Reference No.</li>
          <li class="ma-0">Screenshots of the transaction (If available)</li>
        </ul>
      </p>
      <p>A TayoCash Customer Care Representative will get back to you as soon as possible after you have sent your inquiry.</p>
    </div>

    <h4 id="c_o10">What kind of ID do I need to bring?</h4>
    <div class="paragraph">
      <p>For every Cash Out transaction, members must be ready to present a valid government- issued ID to the Teller.</p>
    </div>
  </div>
</template>
<script>
export default {
  name: 'cashout',
  methods: {
    removehash() {
      this.$nextTick(() => {
        setTimeout(function() {
          window.location.hash = '_'
          history.replaceState(null, null, ' ');
        }, 50)
      })
    }
  }
}
</script>

<style scoped lang='scss'>
  .ma-0 {
    margin: 0 !important;
  }

  .mt-0 {
    margin-top: 0 !important;
  }
</style>
